import { useState, useEffect } from 'react';
import { useClub } from 'src/context/useClub';
import { PrimaryButton } from 'src/shared/buttons';
import { Card, CardTitle } from 'src/shared/widgets/Card';
import css from './index.module.scss';
import cx from 'classnames';
import { SimpleGrid } from '@chakra-ui/react';

type Post = {
  title: string;
  link: string;
  guid: string;
  contentSnippet?: string;
  image?: string;
  content: string;
};

export default function ArticlesPage() {
  const [posts, setPosts] = useState<Post[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedPost, setSelectedPost] = useState<Post | null>(null);
  const {
    club: { info },
  } = useClub();

  useEffect(() => {
    const CORS_PROXY = 'https://api.allorigins.win/raw?url=';

    const loadPosts = async () => {
      if (!info.rssUrl) return;

      setIsLoading(true);
      try {
        const response = await fetch(`${CORS_PROXY}${info.rssUrl}`);
        const text = await response.text();
        const parser = new DOMParser();
        const xml = parser.parseFromString(text, 'application/xml');
        const items = Array.from(xml.querySelectorAll('item'));

        const posts = items.map(item => {
          const contentEncoded =
            item.querySelector('content\\:encoded')?.textContent || item.querySelector('encoded')?.textContent || '';

          // Extract text content and clean it up
          const contentSnippet = contentEncoded
            .replace(/<[^>]*>/g, '') // Remove HTML tags
            .replace(/\s+/g, ' ') // remove whitespaces
            .trim();

          const mediaContent = item.querySelector('media\\:content, media\\:thumbnail')?.getAttribute('url') || '';
          const enclosureImage = item.querySelector('enclosure')?.getAttribute('url') || '';
          const description = item.querySelector('description')?.textContent || '';
          const imageMatch =
            contentEncoded.match(/<img.*?src=["'](.*?)["']/) || description.match(/<img.*?src=["'](.*?)["']/);

          const image = imageMatch?.[1] || mediaContent || enclosureImage;

          return {
            title: item.querySelector('title')?.textContent || '',
            link: item.querySelector('link')?.textContent || '',
            guid: item.querySelector('guid')?.textContent || '',
            contentSnippet,
            image,
            content: contentEncoded,
          };
        });

        setPosts(posts);
      } catch (error) {
        console.error('Error fetching Medium posts:', error);
      } finally {
        setIsLoading(false);
      }
    };

    loadPosts();
  }, [info.rssUrl]);

  const cleanArticleContent = (content: string, title: string, image?: string) => {
    const parser = new DOMParser();
    const doc = parser.parseFromString(content, 'text/html');

    // Remove the first h3 or figure if it matches the title or image
    const elements = doc.body.children;
    for (let i = 0; i < elements.length; i++) {
      const element = elements[i];

      // Remove title (usually in h3)
      if (element.tagName.toLowerCase() === 'h3' && element.textContent?.trim() === title.trim()) {
        element.remove();
        continue;
      }

      // Remove the first figure with matching image
      if (
        element.tagName.toLowerCase() === 'figure' &&
        image &&
        element.querySelector('img')?.getAttribute('src') === image
      ) {
        element.remove();
        continue;
      }
    }

    return doc.body.innerHTML;
  };

  if (selectedPost) {
    const cleanedContent = cleanArticleContent(selectedPost.content || '', selectedPost.title, selectedPost.image);

    return (
      <div className='container-fluid container-lg py-5'>
        <div className='mb-4'>
          <PrimaryButton onClick={() => setSelectedPost(null)} className='ts-fw-500 ts-fs-14'>
            ← Back to Articles
          </PrimaryButton>
        </div>

        <h1 className={cx('mb-4', css.articleTitle)}>{selectedPost.title}</h1>

        {selectedPost.image && (
          <div className='mb-4'>
            <img
              src={selectedPost.image}
              alt={selectedPost.title}
              className='rounded w-100'
              style={{ maxHeight: '400px', objectFit: 'cover' }}
            />
          </div>
        )}

        <div dangerouslySetInnerHTML={{ __html: cleanedContent }} className={cx(css.articleContent)} />
      </div>
    );
  }

  return (
    <div className='container-fluid container-lg py-5'>
      <h3 className='mb-4 text-center'>{info.name} Articles</h3>

      {isLoading ? (
        <div className='text-center'>
          <div className='spinner-border ts-color-primary' role='status'>
            <span className='visually-hidden'>Loading...</span>
          </div>
        </div>
      ) : (
        <SimpleGrid columns={{ base: 1, sm: 2, md: 3 }} spacing={4}>
          {posts.map(post => (
            <Card key={post.guid}>
              {post.image && (
                <div style={{ position: 'relative', paddingTop: '56.25%' }} className='mb-3'>
                  <img
                    src={post.image}
                    alt={post.title}
                    className='rounded'
                    style={{
                      position: 'absolute',
                      top: 0,
                      left: 0,
                      width: '100%',
                      height: '100%',
                      objectFit: 'cover',
                    }}
                  />
                </div>
              )}
              <CardTitle className='mb-2 ts-fs-18'>{post.title}</CardTitle>
              <p className='text-muted ts-fs-14 mb-3'>{post.contentSnippet?.substring(0, 120)}...</p>
              <PrimaryButton onClick={() => setSelectedPost(post)} className='ts-fw-500 ts-fs-14'>
                Read Article →
              </PrimaryButton>
            </Card>
          ))}
        </SimpleGrid>
      )}
    </div>
  );
}
