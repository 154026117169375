import React from 'react';
import { FixtureMonthAccordion } from './FixtureMonthAccordion';
import { TeamFixture } from 'src/api/getTeamFixtures';

const MONTHS = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];

function splitFixtureInMonth(fixtures: TeamFixture): Map<string, TeamFixture> {
  const groupedEvents = new Map<string, TeamFixture>();

  fixtures.sort((a, b) => a.date.getTime() - b.date.getTime());
  for (const event of fixtures) {
    const currentMonth = event.date.getMonth();
    const currentYear = event.date.getFullYear();
    const dateToDisplay = `${MONTHS[currentMonth]} ${currentYear}`;
    const lastMonth = Object.keys(groupedEvents.keys).at(-1) || currentMonth;

    if (currentMonth !== lastMonth) {
      groupedEvents.set(dateToDisplay, [event]);
      continue;
    }

    const oldEvents = groupedEvents.get(dateToDisplay) || [];
    groupedEvents.set(dateToDisplay, oldEvents.concat(event));
  }
  return groupedEvents;
}

function getTodayMonth(): string {
  const today = new Date();
  return `${MONTHS[today.getMonth()]} ${today.getFullYear()}`;
}

interface GroupedEventsProps {
  fixture: TeamFixture;
}

export const GroupedFixtures: React.FC<GroupedEventsProps> = ({ fixture }) => {
  const groupedFixture = splitFixtureInMonth(fixture);
  const areEventsInThisMonth = fixture.some(({ date }) => date.getMonth() === new Date().getMonth());

  return fixture.length ? (
    <div className='d-flex flex-column gap-3 mt-3 mb-5'>
      {Array.from(groupedFixture).map(([month, monthEvents], i) => (
        <FixtureMonthAccordion
          key={month}
          month={month}
          fixture={monthEvents}
          isDefaultOpen={areEventsInThisMonth ? getTodayMonth() === month : i === 0}
        />
      ))}
    </div>
  ) : (
    <div>No events yet</div>
  );
};
