import { apiClient } from 'src/utils/apiClient';

export interface ClubInfo {
  name: string;
  logoUrl: string | null;
  headerUrl: string | null;
  tagline: string;
  email: string;
  phoneNumber: string;
  facebookUrl: string | null;
  instagramUrl: string | null;
  twitterUrl: string | null;
  youtubeUrl: string | null;
  address: string;
  disclaimer: string;
  code: string;
  showTeams: boolean;
  showTeamSquad: boolean;
  showCompetitions: boolean;
  showDownloadApp: boolean;
  showCalendar: boolean;
  allowCompetitionTeamEntry: boolean;
  competitionsTitle: string | null;
  teamsTitle: string | null;
  showContactUs: boolean;
  leagueFlag: boolean;
  orgId: number;
  showUpcomingGames: boolean;
  showRecentGames: boolean;
  showTeamsCarousel: boolean;
  rssUrl: string;
  about: string;
  aboutImageUrl: string;
  history: string;
  historyImageUrl: string;
  teamPoints: number;
  oppositionTeamPoints: number;
}

interface ClubInfoResponse {
  results: {
    basic_number_of_players: number | null;
    created_at: DateTimeString;
    flag_allow_sharing_clips_to_individual_players: 1 | 0;
    flag_limit_of_free_videos: number;
    org_site_address: string | null;
    org_site_disclaimer: string | null;
    org_avatar: string | null;
    org_description: string | null;
    org_id: number;
    org_name: string;
    org_referral_code: string | null;
    org_season_end_day: string | null;
    org_season_month_month: string | null;
    org_season_start_day: string | null;
    org_season_start_month: string | null;
    org_site_code: string;
    org_site_email1: string | null;
    org_site_email2: string | null;
    org_site_facebook: string | null;
    org_site_image1: string | null;
    org_site_image2: string | null;
    org_site_instagram: string | null;
    org_site_phone1: string | null;
    org_site_phone2: string | null;
    org_site_twitter: string | null;
    org_site_url: string | null;
    org_site_youtube: string | null;
    payment_id: number;
    third_party_api_key: string | null;
    third_party_enabled: 1 | 0;
    org_site_show_teams: 0 | 1;
    org_site_show_team_squad: 0 | 1;
    org_site_show_competitions: 0 | 1;
    org_site_show_download_app: 0 | 1;
    org_site_show_calendar: 0 | 1;
    org_site_competitions_team_entry: 0 | 1;
    org_site_competitions_title: string | null;
    org_site_team_title: string | null;
    org_site_show_contactus: 0 | 1;
    league_flag: 0 | 1;
    org_site_rss: string | null;
    org_site_about: string | null;
    org_site_about_image_url: string | null;
    org_site_history: string | null;
    org_site_history_image_url: string | null;
    org_site_show_upcoming_games: 0 | 1;
    org_site_show_recent_games: 0 | 1;
    org_site_show_teams_carousel: 0 | 1;
  }[];
}

export async function getClubInfo(siteCode: string): Promise<ClubInfo> {
  const { results } = await apiClient<ClubInfoResponse>(`/v1/website/info`, { siteCode });
  const response = results[0];

  return {
    address: response.org_site_address || '',
    email: response.org_site_email1 || '',
    facebookUrl: response.org_site_facebook,
    instagramUrl: response.org_site_instagram,
    twitterUrl: response.org_site_twitter,
    youtubeUrl: response.org_site_youtube,
    logoUrl: response.org_avatar,
    headerUrl: response.org_site_image1,
    name: response.org_name,
    phoneNumber: response.org_site_phone1 || '',
    tagline: response.org_description || '',
    disclaimer: response.org_site_disclaimer || '',
    code: response.org_site_code,
    showTeams: Boolean(response.org_site_show_teams),
    showTeamSquad: Boolean(response.org_site_show_team_squad),
    showCompetitions: Boolean(response.org_site_show_competitions),
    showDownloadApp: Boolean(response.org_site_show_download_app),
    showCalendar: Boolean(response.org_site_show_calendar),
    allowCompetitionTeamEntry: Boolean(response.org_site_competitions_team_entry),
    competitionsTitle: response.org_site_competitions_title,
    teamsTitle: response.org_site_team_title,
    showContactUs: Boolean(response.org_site_show_contactus),
    leagueFlag: Boolean(response.league_flag),
    orgId: response.org_id,
    showUpcomingGames: Boolean(response.org_site_show_upcoming_games),
    showRecentGames: Boolean(response.org_site_show_recent_games),
    showTeamsCarousel: Boolean(response.org_site_show_teams_carousel),
    rssUrl: response.org_site_rss ?? '',
    about: response.org_site_about ?? '',
    aboutImageUrl: response.org_site_about_image_url ?? '',
    history: response.org_site_history ?? '',
    historyImageUrl: response.org_site_history_image_url ?? '',
    teamPoints: 0,
    oppositionTeamPoints: 0,
  };
}
