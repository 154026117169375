import React from 'react';
import css from './Footer.module.scss';
import { cx } from 'src/utils/styles';
import { Link } from 'react-router-dom';
import { useAppRoutesContext } from 'src/routes/routes';
import { Mail, MapPin, Phone } from 'react-feather';
import { useClub } from 'src/context/useClub';

interface SectionProps {
  children: React.ReactNode | string;
  title: string;
  className?: string;
}

const Section: React.FC<SectionProps> = ({ title, children, className }) => (
  <div className={cx('d-flex flex-column gap-2', className)}>
    <h6 className='mb-4'>{title}</h6>
    {children}
  </div>
);
interface FooterProps {}

export const Footer: React.FC<FooterProps> = () => {
  const routes = useAppRoutesContext();
  const {
    club: { info },
  } = useClub();

  return (
    <div className={cx(css.wrapper, 'd-flex flex-column overflow-hidden')}>
      <div className={cx(css.topSection, 'd-flex justify-content-between flex-wrap flex-lg-nowrap pb-5')}>
        <Section title='QUICK LINKS' className='flex-shrink-0'>
          <Link to={routes.home}>Home</Link>
          <Link to={routes.teams}>Teams</Link>
          {info.leagueFlag && <Link to={routes.standings}>Standings</Link>}
          <Link to={routes.calendar}>Calendar</Link>
          {info.about && <Link to={routes.about}>About us</Link>}
          {info.rssUrl && <Link to={routes.articles}>Articles</Link>}
        </Section>
        <Section title='CONTACT INFO' className='flex-shrink-0'>
          <p className='mb-0'>Get in touch with us! We love to hear from you.</p>
          {info.email && (
            <a className='d-flex gap-2 align-items-center' href={`mailto:/${info.email}`}>
              <Mail className='ts-color-primary' />
              {info.email}
            </a>
          )}
          {info.address && (
            <a className='d-flex gap-2 align-items-center' href={`https://maps.google.com?q=${info.address}`}>
              <MapPin className='ts-color-primary' />
              {info.address}
            </a>
          )}
          {info.phoneNumber && (
            <a className='d-flex gap-2 align-items-center'>
              <Phone className='ts-color-primary' />
              {info.phoneNumber}
            </a>
          )}
        </Section>
        <Section title={info.name}>
          <p>{info.disclaimer}</p>
          <p className='fw-bold fs-6 mb-0'>Download our app</p>
          <div className='d-flex gap-3'>
            <img
              src='/assets/img/download-appstore.png'
              alt='download appstore'
              className={css.downloadAppImage}
              onClick={() => window.open('https://apps.apple.com/us/app/teamsportz/id1541456437', '_blank')}
            />
            <img
              src='/assets/img/download-playstore.png'
              alt='download playstore'
              className={css.downloadAppImage}
              onClick={() =>
                window.open(
                  'https://play.google.com/store/apps/details?id=com.teamsportz.store&hl=en&gl=US&pli=1',
                  '_blank',
                )
              }
            />
          </div>
        </Section>
      </div>
      <div className={cx(css.bottomSection, 'mt-3  d-flex justify-content-between align-items-center gap-4 py-4')}>
        <img src='/assets/img/teamsportz.jpg' alt='Teamsportz' />
        <p className='mb-0'>Ⓒ 2023 All rights reserved. Site powered by TeamSportz Group Ltd</p>
      </div>
    </div>
  );
};
