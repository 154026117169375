import { Carousel } from 'antd';
import { useClub } from 'src/context/useClub';
import { TeamCardSimple } from '../TeamCard';
import css from './index.module.scss';
import React from 'react';
import type { CarouselRef } from 'antd/es/carousel';

const TeamCarousel = () => {
  const {
    club: { teams },
  } = useClub();

  const carouselRef = React.useRef<CarouselRef>(null);

  const settings = {
    infinite: true,
    slidesToShow: 6,
    slidesToScroll: 1,
    autoplay: true,
    centerMode: true,
    autoplaySpeed: 3000,
    centerPadding: '60px',
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 4,
          centerPadding: '40px',
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          centerPadding: '30px',
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          centerPadding: '20px',
        },
      },
    ],
  };

  const handlePrevClick = () => {
    carouselRef.current?.prev();
  };

  const handleNextClick = () => {
    carouselRef.current?.next();
  };

  if (!teams?.length) {
    return <div>No teams available</div>;
  }

  return (
    <div>
      <div className='container-fluid container-lg d-flex justify-content-between align-items-center'>
        <h3>Our Teams</h3>
        <div className={css.arrowControls}>
          <button className='custom-prev-arrow' onClick={handlePrevClick}>
            ←
          </button>
          <button className='custom-next-arrow' onClick={handleNextClick}>
            →
          </button>
        </div>
      </div>
      <div className={css.carouselWrapper}>
        <Carousel ref={carouselRef} {...settings} arrows={false}>
          {teams.map(team => (
            <TeamCardSimple key={team.id} team={team} />
          ))}
        </Carousel>
      </div>
    </div>
  );
};

export default TeamCarousel;
