import { useClub } from 'src/context/useClub';
import { HertsLeagueStandings, useStandingsQuery } from './queries/standingsQuery';
import { Tabs, Select } from 'antd';
import type { TabsProps } from 'antd';
import { useState } from 'react';
import { DataTable, DataTableColumnDef } from 'src/shared/widgets/DataTable/DataTable';
import { CSS_BREAKPOINTS } from 'src/constants';
import { DataTableItemDialog } from 'src/shared/widgets/DataTable/DataTableItemDialog';
import { RoundedImage } from 'src/shared/widgets/RoundedImage';
import css from './index.module.scss';
import cx from 'classnames';

export default function StandingsPage() {
  const {
    club: { info },
  } = useClub();

  const divisions = ['Division 1', 'Division 2', 'Division 3'];

  const currentYear = new Date().getFullYear();
  const [selectedYear, setSelectedYear] = useState(currentYear);
  // Generate dynamic year options from 2023 to current year
  const yearOptions = Array.from({ length: currentYear - 2024 + 1 }, (_, index) => ({
    value: currentYear - index,
    label: String(currentYear - index),
  }));

  const items: TabsProps['items'] = divisions.map(division => ({
    key: division,
    label: division,
    children: (
      <StandingsTable orgId={Number(info.orgId)} division={division} selectedYear={selectedYear} clubCode={info.code} />
    ),
  }));

  return (
    <div className='container-fluid container-lg py-5'>
      <div className='d-flex align-items-center gap-2'>
        <Tabs
          defaultActiveKey='Division 1'
          items={items}
          tabBarExtraContent={{
            right: (
              <div className='d-flex align-items-center gap-2'>
                <p className='mb-0 ts-fs-16 ts-fw-500'>Season</p>
                <Select value={selectedYear} onChange={setSelectedYear} options={yearOptions} style={{ width: 120 }} />
              </div>
            ),
          }}
          style={{ width: '100%' }}
          className={cx('ant-tabs-justify-between', css.antTabsStackOnMobile)}
        />
      </div>
    </div>
  );
}

// Separate component for the standings table
function StandingsTable({
  orgId,
  division,
  clubCode,
  selectedYear,
}: {
  orgId: number;
  division: string;
  clubCode: string;
  selectedYear: number;
}) {
  const standingsQuery = useStandingsQuery(orgId, selectedYear, division, clubCode);

  const columns: DataTableColumnDef<HertsLeagueStandings>[] = [
    {
      className: 'text-center',
      dataCell: (_dataItem: HertsLeagueStandings, index: number) => <span>{index + 1}</span>,
      key: 'position',
      title: 'Position',
      width: 50,
    },
    {
      dataCell: (dataItem: HertsLeagueStandings) => (
        <span className='d-flex align-items-center gap-2'>
          <RoundedImage url={dataItem.teamAvatar} name={dataItem.teamName} /> {dataItem.teamName ?? '-'}
        </span>
      ),
      key: 'teamName',
      title: 'Team',
      width: 250,
    },
    {
      className: 'text-center',
      dataCell: (dataItem: HertsLeagueStandings) => <span>{dataItem.wins ?? '-'}</span>,
      key: 'wins',
      title: 'Wins',
      width: 50,
      overflowBreakpoint: CSS_BREAKPOINTS.md,
    },
    {
      className: 'text-center',
      dataCell: (dataItem: HertsLeagueStandings) => <span>{dataItem.losses ?? '-'}</span>,
      key: 'losses',
      title: 'Losses',
      width: 50,
      overflowBreakpoint: CSS_BREAKPOINTS.md,
    },
    {
      className: 'text-center',
      dataCell: (dataItem: HertsLeagueStandings) => <span>{dataItem.void ?? '-'}</span>,
      key: 'void',
      title: 'Void',
      width: 50,
      overflowBreakpoint: CSS_BREAKPOINTS.md,
    },
    {
      className: 'text-center',
      dataCell: (dataItem: HertsLeagueStandings) => <span>{dataItem.winPercentage ?? '-'}</span>,
      key: 'winPercentage',
      title: 'Win %',
      width: 50,
      overflowBreakpoint: CSS_BREAKPOINTS.md,
    },
    {
      className: 'text-center',
      dataCell: (dataItem: HertsLeagueStandings) => <span>{dataItem.pointsFor ?? '-'}</span>,
      key: 'pointsFor',
      title: 'Pts For',
      width: 50,
      overflowBreakpoint: CSS_BREAKPOINTS.md,
    },
    {
      className: 'text-center',
      dataCell: (dataItem: HertsLeagueStandings) => <span>{dataItem.pointsAgainst ?? '-'}</span>,
      key: 'pointsAgainst',
      title: 'Pts Against',
      width: 50,
      overflowBreakpoint: CSS_BREAKPOINTS.md,
    },
    {
      className: 'text-center',
      dataCell: (dataItem: HertsLeagueStandings) => <span>{dataItem.pointsDifference ?? '-'}</span>,
      key: 'pointsDifference',
      title: 'Diff',
      width: 50,
      overflowBreakpoint: CSS_BREAKPOINTS.md,
    },
    {
      className: 'text-center',
      dataCell: (dataItem: HertsLeagueStandings) => <span>{dataItem.points ?? '-'}</span>,
      key: 'points',
      title: 'Pts',
      width: 50,
      overflowBreakpoint: CSS_BREAKPOINTS.md,
    },
    {
      className: 'd-md-none flex-shrink-0 flex-grow-0',
      key: 'actions',
      width: 20,
      dataCell: dataItem => <DataTableItemDialog columns={columns} dialogTitle={'Details'} dataItem={dataItem} />,
      dialogDataCell: () => null,
    },
  ];

  if (standingsQuery.isLoading) {
    return (
      <div className='d-flex justify-content-center py-5 mt-5'>
        <div className='spinner-border ts-color-primary' role='status'>
          <span className='visually-hidden'>Loading...</span>
        </div>
      </div>
    );
  }

  return (
    <div>
      <DataTable
        stripped
        data={standingsQuery.data || []}
        columns={columns}
        headerClassName='ts-fw-500 ts-fs-14 mt-3'
      />
    </div>
  );
}
