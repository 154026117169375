const STORAGE_PREFIX = 'ts_club_';

export const storage = {
  setSiteCode(code: string) {
    localStorage.setItem(`${STORAGE_PREFIX}site_code`, code);
  },

  getSiteCode() {
    return localStorage.getItem(`${STORAGE_PREFIX}site_code`);
  },

  clearSiteCode() {
    localStorage.removeItem(`${STORAGE_PREFIX}site_code`);
  },
};
