import React from 'react';
import { ContactInfo } from 'src/pages/contact/ContactInfo';
import { ContactForm } from 'src/pages/contact/ContactForm';
import { StaffList } from 'src/pages/contact/StaffList';

export const ContactPage: React.FC = () => {
  return (
    <div className='container-fluid container-lg d-flex gap-4 flex-column-reverse flex-sm-row py-5'>
      <div className='d-flex flex-column gap-4 w-100'>
        <ContactInfo />
        <ContactForm />
      </div>
      <StaffList className='w-100' />
    </div>
  );
};
