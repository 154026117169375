import { useQuery } from 'react-query';
import { TeamFixture, getTeamFixtures } from 'src/api/getTeamFixtures';

interface TeamFixturesQueryParams {
  siteCode: string;
  teamId?: number;
  season?: number;
  division?: number;
  fromDate?: string;
  toDate?: string;
}

export function useTeamFixturesQuery({
  teamId,
  siteCode,
  season,
  division,
  fromDate,
  toDate,
}: TeamFixturesQueryParams) {
  return useQuery<TeamFixture, Error>(
    ['teamFixtures', teamId, season, division, fromDate, toDate],
    () => getTeamFixtures({ teamId, siteCode, season, division, fromDate, toDate }),
    {
      enabled: !!siteCode,
    },
  );
}
