import React from 'react';

export const APP_ROUTES = {
  home: '/',
  calendar: '/calendar',
  contact: '/contact',
  competitions: {
    base: '/competitions',
    competition: (competitionId: number) => `/competitions/${competitionId}`,
  },
  teams: '/teams',
  standings: '/standings',
  about: '/about',
  articles: '/articles',
} as const;

const AppRoutesContext = React.createContext(APP_ROUTES);

export type AppRoutes = typeof APP_ROUTES;

export function useAppRoutesContext(): AppRoutes {
  return React.useContext(AppRoutesContext);
}
