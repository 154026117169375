import { useClub } from 'src/context/useClub';
import { DarkButton } from 'src/shared/buttons';
import { useNavigate } from 'react-router-dom';
import DOMPurify from 'dompurify';
import css from './index.module.scss';
import cx from 'classnames';

export default function AboutSection() {
  const {
    club: { info },
  } = useClub();
  const navigate = useNavigate();

  const sanitizedHtml = DOMPurify.sanitize(info.about);

  return (
    <div className='container-fluid container-lg mb-4'>
      <div className='row align-items-center'>
        <div className='col-12 col-md-6'>
          <div className='pe-md-4'>
            <h3 className='mb-2 ts-color-tertiary'>WELCOME</h3>
            <h4 className='mb-4 ts-fw-900'>{info.name}</h4>
            <div className={cx('mb-4', css.paragraph16)} dangerouslySetInnerHTML={{ __html: sanitizedHtml }} />
            <DarkButton className='float-end px-4' onClick={() => navigate('/about')}>
              Read more
            </DarkButton>
          </div>
        </div>

        <div className='col-12 col-md-6 mt-4 mt-md-0'>
          <div
            className='rounded-3 ratio ratio-16x9'
            style={{
              backgroundImage: `url(${info.aboutImageUrl || info.logoUrl})`,
              backgroundSize: 'cover',
              backgroundPosition: 'center',
            }}
          />
        </div>
      </div>
    </div>
  );
}
