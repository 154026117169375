import React from 'react';
import { useClub } from 'src/context/useClub';
import LatestHighights from './LatestHighights';
import { ClubCompetition } from 'src/api/getClubCompetitions';
import { getAllTimeDates } from 'src/utils/dates';
import { useHighlightsQuery } from './LatestHighights/highlightsQuery';
import Competitions from './competition/Competitions';
import TeamCarousel from './teamCarousel';
import AboutSection from './aboutSection';
import RecentGames from './games/RecentGames';
import UpcomingMatches from './games/UpcomingMatches';

export const HomePage: React.FC = () => {
  const {
    club: { info, competitions },
  } = useClub();

  const allTimeDates = getAllTimeDates();
  const highlightQuery = useHighlightsQuery({
    page: 1,
    limit: 3,
    filters: {
      fromDate: allTimeDates.startDate,
      toDate: allTimeDates.endDate,
      withVideo: true,
      siteCode: info.code,
    },
    sortAscending: false,
    sortBy: 'created',
  });

  const beCompetitionGroupings: Record<string, ClubCompetition[]> = {};

  for (const competition of competitions) {
    const competitionArea = competition.event.name ?? '';

    if (beCompetitionGroupings[competitionArea]) {
      beCompetitionGroupings[competitionArea] = [...beCompetitionGroupings[competitionArea], competition];
    }
    if (!beCompetitionGroupings[competitionArea] && !!competitionArea) {
      beCompetitionGroupings[competitionArea] = [competition];
    }
  }

  const beGroupsNames = Object.keys(beCompetitionGroupings);

  const commonTailStringArray = [];
  let breakLoop = false;
  for (let i = 0; i < 10; i++) {
    let currentWord = '';
    for (const name of beGroupsNames) {
      if (!currentWord) {
        currentWord = name.split(' ').reverse()[i];
      } else {
        if (currentWord !== name.split(' ').reverse()[i]) {
          breakLoop = true;
        }
      }
    }
    if (breakLoop) break;
    commonTailStringArray.push(currentWord);
  }

  const TABS: Record<string, string> = {};

  for (const name of beGroupsNames) {
    TABS[name] = name;
  }

  return (
    <div>
      {info.about && (
        <div className='bg-white py-5'>
          <AboutSection />
        </div>
      )}

      {info.showUpcomingGames && (
        <div className='py-5'>
          <UpcomingMatches />
        </div>
      )}

      {info.showRecentGames && (
        <div className='bg-white py-5'>
          <RecentGames />
        </div>
      )}

      <div className='container-fluid container-lg mt-5 '>
        <Competitions />
      </div>

      {highlightQuery.data?.highlights.length ? (
        <div className='container-fluid container-lg py-5'>
          <h3>Latest Highlights</h3>
          <LatestHighights highlightQuery={highlightQuery.data} />
        </div>
      ) : null}

      {info.showTeamsCarousel && (
        <div className='my-4 overflow-hidden'>
          <TeamCarousel />
        </div>
      )}
    </div>
  );
};
