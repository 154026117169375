import { Outlet, ScrollRestoration, useMatch, useSearchParams } from 'react-router-dom';
import { MODAL_ROOT_ID } from 'src/shared/modal/ModalDialog';
import { TopBar } from 'src/shared/layout/TopBar';
import { Hero } from 'src/shared/layout/Hero';
import { useAppRoutesContext } from './routes';
import { Footer } from 'src/shared/layout/Footer';
import { cx } from 'src/utils/styles';
import { ClubContextProvider } from 'src/context/ClubContext';
import { useEffect, useState } from 'react';
import { FullScreenSpinner } from 'src/shared/layout/FullScreenSpinner';
import { getClubsMappings } from 'src/api/getClubsMappings';
import { ErrorMessage } from 'src/shared/layout/ErrorMessage';
import css from './AppWrapper.module.scss';
import { QueryClient, QueryClientProvider } from 'react-query';
import { storage } from 'src/utils/storage';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      cacheTime: 1000 * 60 * 60,
      retry: false,
    },
  },
});

export const AppWrapper: React.FC = () => {
  const routes = useAppRoutesContext();
  const isHome = useMatch({ path: routes.home });
  const [searchParams] = useSearchParams();

  const [siteCode, setSiteCode] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (siteCode) return;

    const fetchSiteCode = async () => {
      const domain = window.location.hostname;
      let code: string | null = null;
      const urlCode = searchParams.get('code')?.split('/')[0] || null;

      if (domain.startsWith('club') || domain.startsWith('localhost')) {
        // If URL has a code parameter, use it and update storage
        if (urlCode) {
          code = urlCode;
          // We'll update storage after we get the org ID in ClubContext
        } else {
          // Otherwise check localStorage
          code = storage.getSiteCode();
        }
      } else {
        setIsLoading(true);
        const resultCode = await getClubsMappings(domain, urlCode || '');
        if (resultCode) code = resultCode;
      }

      setSiteCode(code);
      setIsLoading(false);
    };

    fetchSiteCode();
  }, [searchParams, siteCode]); // Add searchParams as dependency to react to URL changes

  if (isLoading) return <FullScreenSpinner />;
  if (!siteCode) return <ErrorMessage message={`Site code '${searchParams.get('code')}' is not a valid code`} />;

  return (
    <ClubContextProvider siteCode={siteCode}>
      <QueryClientProvider client={queryClient}>
        <div id={MODAL_ROOT_ID} />
        <TopBar />
        {isHome ? <Hero /> : null}

        <div className={cx(css.container)}>
          <Outlet />
        </div>

        <Footer />
        <ScrollRestoration />
      </QueryClientProvider>
    </ClubContextProvider>
  );
};
