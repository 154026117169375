import React, { useState } from 'react';
import { FixturesFilters } from './FixturesFilters';
import { useClub } from 'src/context/useClub';
import { DropdownValue } from 'src/shared/widgets/DropdownField/DropdownField';
import { GroupedFixtures } from './GroupedFixtures';
import { LeagueFixtures } from './LeagueFixtures';
import { useTeamFixturesQuery } from './queries/teamFixturesQuery';
import { FullScreenSpinner } from 'src/shared/layout/FullScreenSpinner';
// import { ClubTeam } from 'src/api/getClubTeams';

export const FixturesPage: React.FC = () => {
  const {
    club: { teams, info },
  } = useClub();

  // const allTeamsOption: DropdownValue = {
  //   id: '',
  //   label: 'All',
  // };
  // const [pickedTeam, setPickedTeam] = useState<DropdownValue>(allTeamsOption);

  const defaultTeam = teams[0];
  const [pickedTeam, setPickedTeam] = useState<DropdownValue>({
    id: defaultTeam.id.toString(),
    label: defaultTeam.name,
  });

  // const team = teams.find(team => team.id === Number(pickedTeam.id));
  const fixturesQuery = useTeamFixturesQuery({
    teamId: Number(pickedTeam.id),
    siteCode: info.code,
  });

  if (info.leagueFlag) return <LeagueFixtures />;

  return (
    <div className='container-fluid container-lg py-5'>
      <h2 className='mb-3'>Calendar</h2>
      <FixturesFilters
        pickedTeam={pickedTeam}
        onTeamChange={setPickedTeam}
        className='mb-4'
        // filteredTeams={[{ id: 0, name: 'All' } as ClubTeam, ...teams]}
        filteredTeams={teams}
      />
      {fixturesQuery.isLoading ? <FullScreenSpinner /> : <GroupedFixtures fixture={fixturesQuery.data || []} />}
    </div>
  );
};
