import React from 'react';
import { BaseButton, BaseButtonProps } from 'src/shared/buttons/BaseButton';
import { cx } from 'src/utils/styles';
import css from './index.module.scss';

export const PrimaryButton: React.FC<BaseButtonProps> = props => {
  return <BaseButton {...props} className={cx(css.primaryBtn, props.className)} />;
};

export const SecondaryButton: React.FC<BaseButtonProps> = props => {
  return <BaseButton {...props} className={cx(css.secondaryBtn, props.className)} />;
};

export const CancelButton: React.FC<BaseButtonProps> = props => {
  return <BaseButton {...props} className={cx(css.cancelBtn, props.className)} />;
};

export const SubtleButton: React.FC<BaseButtonProps> = props => {
  return (
    <BaseButton {...props} className={cx(css.subtleBtn, props.selected && css.selected, props.className)}>
      <div>{props.children}</div>
    </BaseButton>
  );
};

export const TransparentButton: React.FC<BaseButtonProps> = props => {
  return <BaseButton {...props} className={cx('bg-transparent ts-text ts-fw-500', props.className, css.transparent)} />;
};

export const LinkButton: React.FC<BaseButtonProps> = props => {
  return (
    <BaseButton
      {...props}
      className={cx(
        css.linkBtn,
        props.selected ? 'ts-color-primary ts-fw-500' : 'ts-text',
        'bg-transparent',
        props.className,
      )}
    />
  );
};

export const PrimaryInvertedButton: React.FC<BaseButtonProps> = props => (
  <BaseButton
    {...props}
    className={cx('ts-color-primary bg-transparent ts-fs-14', css.linkBtnPrimary, props.className)}
  />
);
export const SecondaryInvertedButton: React.FC<BaseButtonProps> = props => (
  <BaseButton
    {...props}
    className={cx('ts-color-secondary bg-transparent ts-fs-14', css.linkBtnSecondary, props.className)}
  />
);

export const DarkButton: React.FC<BaseButtonProps> = props => {
  return <BaseButton {...props} className={cx(css.darkBtn, props.className)} />;
};
