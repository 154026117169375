import { SimpleGrid } from '@chakra-ui/react';
import { useClub } from 'src/context/useClub';
import { TeamCard } from '../home/TeamCard';
import { Tabs } from 'antd';
import type { TabsProps } from 'antd';

export default function TeamPage() {
  const {
    club: { info, teams },
  } = useClub();

  const divisions = ['All', 'Division 1', 'Division 2', 'Division 3'];
  const divisionMapping = {
    'Division 1': 'div1',
    'Division 2': 'div2',
    'Division 3': 'div3',
  };

  const items: TabsProps['items'] = divisions.map(division => ({
    key: division,
    label: division,
    children: (
      <SimpleGrid columns={{ base: 1, sm: 2, md: 3, lg: 4 }} spacing={4}>
        {teams
          .filter(team =>
            division === 'All' ? true : team.division === divisionMapping[division as keyof typeof divisionMapping],
          )
          .map(team => (
            <TeamCard key={team.id} team={team} />
          ))}
      </SimpleGrid>
    ),
  }));

  return (
    teams.length > 0 &&
    info.showTeams && (
      <div className='mt-2 container-fluid container-lg py-5'>
        <h3>{info.teamsTitle || 'Our Teams'}</h3>
        <Tabs defaultActiveKey='All' items={items} style={{ width: '100%' }} className='ant-tabs-justify-between' />
      </div>
    )
  );
}
