import React from 'react';
import css from './TextField.module.scss';
import { cx } from 'src/utils/styles';
import { FormFieldWrapper } from '../FormFieldWrapper/FormFieldWrapper';

interface TextFieldProps {
  onChange(value: string): void;
  value: string;
  className?: string;
  title: string;
  isDisabled?: boolean;
  isErrorVisible?: boolean;
  placeholder?: string;
  isRequired?: boolean;
  errorMessage?: string;
  type?: 'text' | 'email';
}

export const TextField: React.FC<TextFieldProps> = ({
  className,
  title,
  placeholder,
  isRequired,
  onChange,
  isDisabled,
  isErrorVisible,
  errorMessage,
  value,
  type = 'text',
}) => {
  return (
    <FormFieldWrapper title={title} isErrorVisible={isErrorVisible} errorMessage={errorMessage} isRequired={isRequired}>
      <input
        aria-label={title}
        className={cx('w-100 ts-text-overflow', css.field, isErrorVisible && css.invalidField, className)}
        disabled={isDisabled}
        name={title}
        placeholder={placeholder}
        required={isRequired}
        title={title}
        value={value}
        onChange={event => onChange(event.target.value)}
        type={type}
      />
    </FormFieldWrapper>
  );
};
