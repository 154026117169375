import { useRecentGamesQuery } from './queries/gamesQuery';
import { useClub } from 'src/context/useClub';
import { PrimaryInvertedButton } from 'src/shared/buttons';
import { useNavigate } from 'react-router-dom';
import css from './RecentGames.module.scss';

export default function RecentGames() {
  const {
    club: { info },
  } = useClub();
  const recentGamesQuery = useRecentGamesQuery(info.orgId, info.leagueFlag, info.code);

  const navigate = useNavigate();

  return (
    <div className='container-fluid container-lg'>
      <h3 className='mb-3'>Recent games</h3>

      <div className='d-flex flex-column gap-3'>
        {recentGamesQuery.data?.map(game => (
          <div className='overflow-hidden' style={{ background: '#eff1f9', borderRadius: '15px' }}>
            <div className='d-flex align-items-center justify-content-center   border-bottom p-2 gap-4'>
              <div className={`col text-end ts-fw-500 ${css.responsive18}`}>{game.teamName}</div>
              <img
                src={game.teamAvatar}
                className='rounded-circle object-fit-cover d-none d-md-block'
                style={{ width: '70px', height: '70px' }}
              />
              <div className={`col-auto fw-bold ${css.responsive22}`}>{game.teamPoints ?? 0}</div>
              <div className='col-auto'>-</div>
              <div className={`col-auto fw-bold ${css.responsive22}`}>{game.oppositionTeamPoints ?? 0}</div>
              <img
                src={game.oppositionTeamAvatar || 'assets/img/shield_place_holder.png'}
                className='rounded-circle object-fit-cover d-none d-md-block'
                style={{ width: '70px', height: '70px' }}
              />
              <div className={`col ts-fw-500 ${css.responsive18}`}>{game.oppositionName}</div>
            </div>
          </div>
        ))}
      </div>

      <div className='text-end mt-2'>
        <PrimaryInvertedButton className='ts-fw-500' onClick={() => navigate('/standings')}>
          View standings
        </PrimaryInvertedButton>
      </div>
    </div>
  );
}
