import React, { useState } from 'react';
import { FixturesFilters } from './FixturesFilters';
import { useClub } from 'src/context/useClub';
import { DropdownValue } from 'src/shared/widgets/DropdownField/DropdownField';
import { GroupedFixtures } from './GroupedFixtures';
import { useTeamFixturesQuery } from './queries/teamFixturesQuery';
import { FullScreenSpinner } from 'src/shared/layout/FullScreenSpinner';
import { Tabs, Select } from 'antd';
import type { TabsProps } from 'antd';
import cx from 'classnames';
import css from '../standings/index.module.scss';
import { ClubTeam } from 'src/api/getClubTeams';

export const LeagueFixtures: React.FC = () => {
  const {
    club: { teams, info },
  } = useClub();

  const allTeamsOption: DropdownValue = {
    id: '',
    label: 'All',
  };

  const [pickedTeam, setPickedTeam] = useState<DropdownValue>(allTeamsOption);

  const currentYear = new Date().getFullYear();
  const [selectedYear, setSelectedYear] = useState(currentYear);

  // Generate dynamic year options from 2024 to current year
  const yearOptions = Array.from({ length: currentYear - 2024 + 1 }, (_, index) => ({
    value: currentYear - index,
    label: String(currentYear - index),
  }));

  const divisions = [
    { label: 'All', value: undefined },
    { label: 'Division 1', value: 1 },
    { label: 'Division 2', value: 2 },
    { label: 'Division 3', value: 3 },
  ];

  const divisionMapping = {
    'Division 1': 'div1',
    'Division 2': 'div2',
    'Division 3': 'div3',
  };

  const [selectedDivision, setSelectedDivision] = useState<string>('All');

  const filteredTeams = teams.filter(team =>
    selectedDivision === 'All'
      ? true
      : team.division === divisionMapping[selectedDivision as keyof typeof divisionMapping],
  );

  const items: TabsProps['items'] = divisions.map(division => ({
    key: division.label,
    label: division.label,
    children: (
      <div className='container-fluid container-lg'>
        <FixturesFilters
          pickedTeam={pickedTeam}
          onTeamChange={setPickedTeam}
          className='mb-4'
          filteredTeams={[{ id: 0, name: 'All' } as ClubTeam, ...filteredTeams]}
        />
        <FixturesContent
          teamId={Number(pickedTeam.id)}
          siteCode={info.code}
          division={division.value}
          season={selectedYear}
        />
      </div>
    ),
  }));

  return (
    <div className='container-fluid container-lg py-5'>
      <div className='d-flex align-items-center gap-2'>
        <Tabs
          defaultActiveKey='All'
          items={items}
          onChange={setSelectedDivision}
          tabBarExtraContent={{
            right: (
              <div className='d-flex align-items-center gap-2'>
                <p className='mb-0 ts-fs-16 ts-fw-500'>Season</p>
                <Select value={selectedYear} onChange={setSelectedYear} options={yearOptions} style={{ width: 120 }} />
              </div>
            ),
          }}
          style={{ width: '100%' }}
          className={cx('ant-tabs-justify-between', css.antTabsStackOnMobile)}
        />
      </div>
    </div>
  );
};

interface FixturesContentProps {
  teamId?: number;
  siteCode: string;
  division?: number;
  season?: number;
  // fromDate: '2024-01-01',
  // toDate: '2024-12-31',
}

const FixturesContent: React.FC<FixturesContentProps> = ({ teamId, siteCode, division, season }) => {
  const fixturesQuery = useTeamFixturesQuery({
    teamId,
    siteCode,
    division,
    season,
  });

  if (fixturesQuery.isLoading) {
    return <FullScreenSpinner />;
  }

  return <GroupedFixtures fixture={fixturesQuery.data || []} />;
};
