import React from 'react';
import { MapPin } from 'react-feather';
import { TeamFixture } from 'src/api/getTeamFixtures';
import { CSS_BREAKPOINTS } from 'src/constants';
import { Accordion } from 'src/shared/widgets/Accordion/Accordion';
import { DataTable, DataTableColumnDef } from 'src/shared/widgets/DataTable/DataTable';
import { DataTableItemDialog } from 'src/shared/widgets/DataTable/DataTableItemDialog';
import { formatTime } from 'src/utils/dates';
import { useIsMobile } from 'src/utils/hooks/useIsMobile';

interface EventMonthAccordionProps {
  month: string;
  fixture: TeamFixture;
  isDefaultOpen?: boolean;
}

export const FixtureMonthAccordion: React.FC<EventMonthAccordionProps> = ({ month, fixture, isDefaultOpen }) => {
  const isMobile = useIsMobile(CSS_BREAKPOINTS.sm);

  const columns: DataTableColumnDef<TeamFixture[number]>[] = [
    {
      key: 'date',
      dataCell: dataItem => <span>{dataItem.date.toDateString()}</span>,
      width: 120,
      className: 'flex-shrink-0',
      title: 'Date',
      overflowBreakpoint: CSS_BREAKPOINTS.sm,
    },
    {
      key: 'tip-off',
      className: 'flex-shrink-0',
      dataCell: dataItem => <span>{formatTime(dataItem.date)}</span>,
      width: 80,
      title: 'Tip Off',
      overflowBreakpoint: CSS_BREAKPOINTS.sm,
    },
    {
      className: 'flex-shrink-0 ts-text-overflow',
      key: 'home',
      dataCell: dataItem => <span>{dataItem.venueIsHome ? dataItem.teamName : dataItem.opponent}</span>,
      width: isMobile ? 100 : 180,
      title: 'Home',
    },
    {
      className: 'flex-shrink-0 d-flex align-self-center',
      width: 80,
      key: 'score',
      title: 'Score',
      dataCell: dataItem => <span>{`${dataItem.teamPoints} - ${dataItem.oppositionTeamPoints}`}</span>,
      overflowBreakpoint: CSS_BREAKPOINTS.sm,
    },
    {
      className: 'flex-shrink-0 ts-text-overflow',
      key: 'away',
      dataCell: dataItem => <span>{dataItem.venueIsHome ? dataItem.opponent : dataItem.teamName}</span>,
      width: isMobile ? 100 : 180,
      title: 'Away',
    },
    {
      className: 'flex-grow-1 ts-text-overflow',
      key: 'location',
      dialogDataCell: dataItem => (
        <tr>
          <td className='py-2 pe-3 ts-fw-500 align-middle text-nowrap'>Venue</td>
          <td className='py-2 align-middle'>{dataItem.venue}</td>
        </tr>
      ),
      dataCell: dataItem => (
        <div className='d-flex align-items-center gap-1'>
          <MapPin className='ts-color-primary flex-shrink-0' size={16} />
          <span className='ts-text-overflow'>{dataItem.venue || '-'}</span>
        </div>
      ),
      width: 200,
      title: 'Location',
      overflowBreakpoint: CSS_BREAKPOINTS.sm,
    },
    // {
    //   key: 'status',
    //   className: `${!info.leagueFlag ? 'd-none' : ''} flex-shrink-0`,
    //   dataCell: dataItem => {
    //     if (!dataItem.leagueFlag) return null;
    //     return (
    //       <div className='d-flex align-items-center'>
    //         {dataItem.eventConfirmationStatus ? (
    //           <p
    //             className='ts-fs-12 m-0'
    //             style={{
    //               background: '#90C76599',
    //               padding: '5px 10px',
    //               borderRadius: '6px',
    //               border: '1px solid #BBC3D3',
    //             }}
    //           >
    //             CONFIRMED
    //           </p>
    //         ) : (
    //           <p
    //             className='ts-fs-12 m-0'
    //             style={{
    //               background: '#bbc3d340',
    //               padding: '5px 10px',
    //               borderRadius: '6px',
    //               border: '1px solid #BBC3D3',
    //             }}
    //           >
    //             PENDING
    //           </p>
    //         )}
    //       </div>
    //     );
    //   },
    //   width: 120,
    //   title: 'Status',
    //   overflowBreakpoint: CSS_BREAKPOINTS.md,
    // },
    {
      className: 'flex-grow-0',
      width: 20,
      key: 'actions',
      dialogDataCell: () => null,
      dataCell: dataItem => <DataTableItemDialog columns={columns} dialogTitle={'Details'} dataItem={dataItem} />,
    },
  ];

  return (
    <Accordion title={month} isDefaultOpen={isDefaultOpen}>
      <DataTable data={fixture} columns={columns} headerClassName='ts-fw-500 ts-fs-14' />
    </Accordion>
  );
};
