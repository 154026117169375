import { useQuery, UseQueryResult } from 'react-query';
import { getStandings } from './getStandings';

export interface HertsLeagueStandings {
  id: number;
  teamName: string;
  teamAvatar: string;
  noOfGames: number;
  wins: number;
  losses: number;
  void: number;
  winPercentage: number;
  pointsFor: number;
  pointsAgainst: number;
  pointsDifference: number;
  points: number;
  createdAt: string;
  updatedAt: string;
  division: string;
  season: string;
}

export function useStandingsQuery(
  orgId: number,
  season: number,
  division: string,
  siteCode: string,
): UseQueryResult<HertsLeagueStandings[], Error> {
  return useQuery(
    ['standings', orgId, season, division],
    async () => {
      const response = await getStandings(orgId, season, division, siteCode);

      return response.response.map<HertsLeagueStandings>(event => ({
        id: event.leaderboard_id,
        teamName: event.team_name,
        teamAvatar: event.team_avatar,
        noOfGames: event.leaderboard_no_games,
        wins: event.leaderboard_wins,
        losses: event.leaderboard_losses,
        void: event.leaderboard_draws,
        winPercentage: event.leaderboard_win_percentage,
        pointsFor: event.leaderboard_points_for,
        pointsAgainst: event.leaderboard_points_against,
        pointsDifference: event.leaderboard_points_diff,
        points: event.leaderboard_total_points,
        createdAt: event.created_at,
        updatedAt: event.updated_at,
        division: event.division,
        season: event.season.toString(),
      }));
    },
    {
      enabled: !!division,
      staleTime: 1000 * 60 * 30,
    },
  );
}
