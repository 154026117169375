import { apiClient } from 'src/utils/apiClient';

interface GetStandingsResponse {
  code: number;
  error: boolean;
  message: string;
  response: {
    leaderboard_id: number;
    leaderboard_team_id: number;
    leaderboard_no_games: number;
    leaderboard_wins: number;
    leaderboard_win_percentage: number;
    leaderboard_draws: number;
    leaderboard_losses: number;
    leaderboard_points_diff: number;
    leaderboard_points_against: number;
    leaderboard_points_for: number;
    leaderboard_total_points: number;
    created_at: string;
    updated_at: string;
    org_id: number;
    season: number;
    division: string;
    team_name: string;
    team_avatar: string;
  }[];
}

export async function getStandings(orgId: number, season: number, division: string, siteCode: string) {
  return apiClient<GetStandingsResponse>(
    `/v1/website/league/standings?org_id=${orgId}&season=${season}&division=${division}`,
    {
      siteCode,
    },
  );
}
