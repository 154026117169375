import React, { useEffect, useMemo, useState } from 'react';
import { ClubCompetition, getClubCompetitions } from 'src/api/getClubCompetitions';
import { ClubInfo, getClubInfo } from 'src/api/getClubInfo';
import { ClubTeam, getClubTeams } from 'src/api/getClubTeams';
import { ErrorPage } from 'src/shared/layout/ErrorPage';
import { FullScreenSpinner } from 'src/shared/layout/FullScreenSpinner';
import { storage } from 'src/utils/storage';

export interface Club {
  info: ClubInfo;
  teams: ClubTeam[];
  competitions: ClubCompetition[];
}

export interface ClubContextResult {
  isLoading: boolean;
  error: Error | null;
  club: Club;
}

interface ClubContextProps {
  siteCode: string;
  children: React.ReactNode;
}

export const ClubContext = React.createContext<ClubContextResult | null>(null);

export const ClubContextProvider: React.FC<ClubContextProps> = ({ children, siteCode }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [clubData, setClubData] = useState<Club | null>(null);
  const [error, setError] = useState<Error | null>(null);

  useEffect(() => {
    const fetchInfo = async () => {
      try {
        setIsLoading(true);
        const info = await getClubInfo(siteCode);
        storage.setSiteCode(info.code);

        const teams = await getClubTeams(siteCode);
        const competitions = await getClubCompetitions(siteCode);

        setClubData({ info, teams, competitions });
      } catch (err) {
        setClubData(null);
        setError(err as Error);
      }
      setIsLoading(false);
    };
    fetchInfo();
  }, [siteCode]);

  const result = useMemo(
    (): ClubContextResult => ({ isLoading, error, club: clubData as Club }),
    [clubData, error, isLoading],
  );

  return (
    <ClubContext.Provider value={result}>
      {isLoading ? <FullScreenSpinner /> : error ? <ErrorPage error={error} /> : children}
    </ClubContext.Provider>
  );
};
